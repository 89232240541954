let translatableTextDefault = {
    type: 'translatable-text',
    value: null,
    translations: []
}
module.exports = {
    store_product_id: null,
    tag: JSON.parse(JSON.stringify(translatableTextDefault)),
    name: JSON.parse(JSON.stringify(translatableTextDefault)),
    discount: null,
    discount_info: JSON.parse(JSON.stringify(translatableTextDefault)),
    discount_background: null,
    original_price: null,
    description: JSON.parse(JSON.stringify(translatableTextDefault)),
    sale_price: null,
    default: false
}

